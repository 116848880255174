@import "ember-power-select";


.popover {
    z-index: 1000;
}
.popover-header{
    font-size: 1rem !important;
    margin-bottom: 0px !important;
}

.ember-power-select-trigger_cui{
    border-radius: var(--cui-border-radius);
    border: var(--cui-border);
    background: var(--cui-form-background);
    color: var(--cui-form-value-color);
    box-shadow: none;
    transition: all var(--cui-animate-speed) var(--cui-animate-timing-function);
    flex: 1;
    flex-shrink: 0;
    -webkit-appearance: none;
    order: 3;
    min-height: var(--cui-form-size);
    // font-size: var(--cui-font-size);
    font-family: var(--cui-font-stack);
    overflow: hidden;


    .ember-power-select-placeholder, .ember-power-select-selected-item{
        // line-height: 2.4rem;
        height: var(--cui-form-size);
        max-height: var(--cui-form-size);
        font-weight: var(--cui-form-value-font-weight);
        padding: var(--cui-form-padding);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }

    .ember-power-select-status-icon{
        visibility: hidden;
    }

}

.ember-basic-dropdown{
    .ember-power-select-clear-btn{
        font-size: 20px;
    }

    .dropdown__menu{
        border: none !important;
        box-shadow: var(--cui-shadow-outset);

        a{
            z-index: calc(var(--cui-dropdown-zindex) + 1);
        }

        .ember-power-select-group{
            margin-bottom: unset !important;
            >a,li{
                padding-left: calc(var(--cui-spacing) + var(--cui-spacing-qtr)) !important;
            }
        }

        ul[role="group"]{
            padding-left: unset !important;
            border-bottom: var(--cui-border);
        }
        
        ul:not([role="group"]){

            .ember-power-select-group-name{
                font-family: var(--cui-font-stack);
                font-size: var(--cui-font-size);
                font-weight: 300;
                padding: var(--cui-spacing-half);
                display: inline-flex;
                font-style: italic;
            }

            list-style: none;
            // display: inline-flex;
            display: block;
            flex-direction: column;
            background: var(--cui-background-color);
            border: var(--cui-border);
            border-top: var(--cui-accent-thickness) solid var(--cui-accent-color);
            border-radius: var(--cui-border-radius);
            color: var(--cui-font-color);
            overflow: hidden auto;
            // position: absolute;
            pointer-events: auto;
            top: calc(100% + var(--cui-spacing-qtr));
            min-width: var(--cui-dropdown-menu-min-width);
            max-height: var(--cui-dropdown-max-height);
            text-align: left;
            box-shadow: var(--cui-shadow-outset);
            user-select: none;
            z-index: var(--cui-dropdown-zindex);
            
            opacity: 1;
            height: auto;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            transition: opacity var(--cui-animate-speed);

            width: 100%;
            padding-left: 0px;

            li:not(.ember-power-select-group){
                height: 35px;
                line-height: inherit;
                // display: inline-flex;
                display: flex;
                flex-shrink: 0;
                align-items: center;
                width: 100%;
                position: relative;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: none;
                white-space: nowrap;
                padding: var(--cui-dropdown-menu-item-padding);
                // padding: 0 var(--cui-spacing-dbl) 0 var(--cui-spacing-half);
                color: var(--cui-inactive-color);
                transition: color .5s var(--cui-animate-timing-function),background-color .25s var(--cui-animate-timing-function),box-shadow .5s var(--cui-animate-timing-function);
                
                &[aria-current=true]{
                    background-color: var(--cui-background-hover);
                    color: var(--cui-active-color);
                }

                &[aria-selected=true]{
                    color: var(--cui-active-color);
                    background-color: var(--cui-background-hover);

                    &:after{
                        color: var(--cui-active-color);
                        position: absolute;
                        right: var(--cui-spacing-half);
                        font-weight: 700;
                        content: "";
                        font-family: "cui-font";
                        font-size: inherit;
                        font-style: normal;
                        font-weight: normal;
                    }
                }
            }
        }
    }

}